import React from 'react'
import { Grid, Link, Typography } from '@material-ui/core'


const Hours = (props) => {

    return (
        <div>
            <Typography style={{ textAlign: 'center', color: 'black' }}><b>hours</b></Typography>
            <br />
            <div style={{ width: '100%' }}>
                <Grid container>
                    <Grid item xs={6} >
                        <Typography style={{ color: 'black', textAlign: 'right', paddingRight: '6px' }}>
                            Mon-Sat:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ color: 'black', textAlign: 'left', paddingLeft: '6px'}}>
                            6AM - 2PM
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} >
                        <Typography style={{ color: 'black', textAlign: 'right', paddingRight: '6px' }}>
                        Sun:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ color: 'black', textAlign: 'left', paddingLeft: '6px'}}>
                        6AM - 1PM
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <br/>
        </div>
    )
}

export default Hours;
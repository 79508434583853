const background = {
    backgroundColor: '#f2eee5',
}

const headerLinks = {
    color: '#FFFFFF'
}

const selectedHeaderLink = {
    color: '#FFFFFF',
    textDecoration: 'underline'
}

const contentBackground = {
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    backgroundColor: 'rgba(00, 00, 00, .6)',
    display: 'inline-block',
    padding: '12px',
    borderRadius: 10,
}

const headerBackground = {
    backgroundColor: 'rgba(00, 00, 00, .8)',
    padding: '12px',
}


export { background, headerLinks, selectedHeaderLink, contentBackground, headerBackground }

import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import { menuHeader } from './styles'

const Oatmeal = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>OATMEAL</b>
            </Typography>
            <Grid container>
                <Grid item xs={12} md={5}>
                    <br/>
                    <Typography>
                        <b>Basic Oats</b><br/>
                        Oatmeal Plain or with Brown Sugar 4.00
                    </Typography>
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={12} md={5}>
                    <br/>
                    <Typography>
                        <b>Hikers Oats</b><br/>
                        Oatmael, Honey, and Beries 6.00
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Oatmeal;
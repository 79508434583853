import React from 'react'
import { Grid, Link, Typography } from '@material-ui/core'


const Address = (props) => {

    return (
        <div style={{textAlign: 'center'}}>
            <Typography style={{ color: 'black'}}><b>address</b></Typography>
            <br />
            <Typography style={{ color: 'black'}}>
                160 Main St.
            </Typography>
            <Typography style={{ color: 'black'}}>
                Kingston, NH 03848
            </Typography>
            <a 
                style={{textAlign: 'center', color: 'black'}}
                href="https://www.google.com/maps/place/160+Main+St,+Kingston,+NH+03848/@42.9362354,-71.0567476,17z/data=!3m1!4b1!4m5!3m4!1s0x89e2fa5e6c7e5d03:0x3ec01be50130c544!8m2!3d42.9362354!4d-71.0545589">
                directions
            </a>
            <br /><br />
        </div>
    )
}

export default Address;
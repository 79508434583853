
const menuHeader = {
    textAlign: 'center',
    backgroundColor: '#facc9a',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: 10,
    color: 'black'
}

export { menuHeader }
import React from 'react'
import Header from '../components/NewHeader'
import { Typography } from '@material-ui/core'
import { contentBackground } from '../styles'

const About = () => {

    return (
        <div>
            <div style={{
                backgroundImage: `url(/images/resterauntWide.jpg)`,
                backgroundRepeat: "no-repeat",
                height: "100vh",
                width: "100%",
                textAlign: 'center',
                backgroundSize: 'cover'
            }}>
                <Header />
                <div style={{...contentBackground, marginTop:'54px'}}>
                    <Typography>
                        Benson’s Cafe was created by a local family and grown by the community. The vision of this restaurant is to take care of everyone who walks through the door and give them a chance to enjoy a meal made by a family who truly understands how important a homemade meal is. Benson’s Cafe is striving to be a place of comfort, cleanliness, convenience, and care.
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default About;

import React from 'react'
import { Grid, Link, Typography } from '@material-ui/core'


const Phone = (props) => {

    return (
        <div style={{textAlign: 'center'}}>
            <Typography style={{ color: 'black'}}><b>phone</b></Typography>
            <br />
            <Typography style={{ color: 'black'}}>
                (603) 244-1331
            </Typography>
            <br />
        </div>
    )
}

export default Phone;
import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core'
import Hours from '../components/Hours'
import Address from '../components/Address'
import Phone from '../components/Phone'
import Header from '../components/NewHeader'
import { contentBackground } from '../styles';


function Home() {
  //TODO consider making images rotate
  return (
    <div>
      <div style={{
        backgroundImage: `url(/images/table.jpg)`,
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        textAlign: 'center',
        backgroundSize: 'cover'
      }}>
        <Header />
        <div style={{ ...contentBackground, marginTop: '54px', color: '#fa8072' }}>
          <h1>NOW OPEN 7 DAYS</h1>
          <br/>
          <p>160 Main St. Kingston, NH 03848</p>
        </div>
      </div>
      <br /><br /><br /><br />
      <Grid container>
        <Grid item sm={0} md={1} />
        <Grid item xs={12} sm={12} md={2}>
          <Phone />
        </Grid>
        <Grid item sm={0} md={1} />
        <Grid item xs={12} sm={12} md={3}>
          <Address />
        </Grid>
        <Grid item sm={0} md={1} />
        <Grid item xs={12} sm={12} md={3}>
          <Hours />
        </Grid>
        {/* <Grid item sm={0} md={1} /> */}


      </Grid>
      <br /><br /><br /><br />
    </div>
  );
}

export default Home;

import React from 'react';
import { Typography } from "@material-ui/core";
import { menuHeader } from '../breakfastMenuItems/styles'

const Sides = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>SIDES</b>
            </Typography>
            <br/>
            <Typography>
                French Fries 5.00
            </Typography>
            <Typography>
                Onion Rings 6.00
            </Typography>
        </div>
    )
}

export default Sides;
import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from './components/NewHeader';
import Home from './pages/home';
import Breakfast from './components/breakfast';
import Lunch from './components/lunch';
import Menu from './pages/menu';
import About from './pages/about'
import {background} from './styles';
import Contact from './pages/contact';

function App() {
  return (
    <div className="App" style={background}>
      <BrowserRouter>
        <Switch>
          <Route path="/menu" component={Menu} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route default path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react';
import { Typography } from "@material-ui/core";
import { menuHeader } from './styles'

const BreakfastSpecials = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>BREAKFAST SPECIALS</b>
            </Typography><br/>
            <Typography>
                <b>The Healthy Riser</b><br/>
                3 Egg Whites, Saut&#232;ed Vegetables,<br/>
                Fruit Cup and Toast 13.00
            </Typography><br/>
            <Typography>
                <b>The Breakfast Sampler</b><br/>
                2 Eggs, 2 Bacon, 2 Sausage,<br/>
                2 Pancakes, and Toast 16.00
            </Typography><br/>
            <Typography>
                <b>The Southern Plate</b><br/>
                2 Eggs, 2 Sausage, Home-fries,<br/>
                House-made Baked Beans, and<br/>
                Grilled Homemade Biscuit Topped<br/>
                with Sausage Gravy 13.50
            </Typography><br/>
            <Typography>
                <b>Steak and Eggs</b><br/>
                House Marinated Steak Tips with<br/>
                2 Eggs, Toast, and Choice of Home-fries,<br/>
                House-made Baked Beans,<br/>
                or Fruit Cup 25.00 
            </Typography>
        </div>
    )
}

export default BreakfastSpecials;
import React from 'react';
import { Typography } from "@material-ui/core";
import { menuHeader } from './styles'

const Griddle = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>FROM THE GRIDDLE</b>
            </Typography>
            <Typography>
                Served with Butter and Syrup
            </Typography><br/>
            <Typography>
                <b>Pancakes</b><br/>
                Single Stack 3.00 &#8226; Double Stack 6.00 &#8226; Tripple Stack 9.00<br/>
            </Typography><br/>
            <Typography>
                <b>French Toast</b><br/>
                Single Slice 3.00 &#8226; Double Slice 6.00 &#8226; Triple Slice 9.00
            </Typography><br/>
            <Typography>
                with Bacon or Sausage Patties add 5.00
                Ham or Sausage Links add 4.00
            </Typography>
        </div>
    )
}

export default Griddle;
import React from 'react';
import { Typography } from "@material-ui/core";
import { menuHeader } from '../breakfastMenuItems/styles'

const Beverages = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>BEVERAGES</b>
            </Typography>
            <br/>
            <Typography>
                Hot Chocolate 3.00
            </Typography><br/>
            <Typography>
                Coffee or Tea 3.00
            </Typography><br/>
            <Typography>
                Raspberry Ice Tea, Unsweetened Tea 3.25
            </Typography><br/>
            <Typography>
                Coke, Diet Coke, Sprite, Dr. Pepper<br/>
                Orange Soda 2.75
            </Typography><br/>
            <Typography>
                Juice: Apple, Cranberry 2.50/3.75
            </Typography><br/>
            <Typography>
                Milk 2.50/3.75
            </Typography><br/>
            <Typography>
                Chocolate Milk 3.50/5.00
            </Typography>
            <Typography>
                Orange Juice 2.75/4.00
            </Typography>
        </div>
    )
}

export default Beverages;
import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import { menuHeader } from './styles'

const BreakfastSandwiches = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography >
                <b style={menuHeader}>BREAKFAST SANDWICHES</b>
            </Typography>
            <Typography>
                All Breakfast Sandwiches are Served with Your Choice of Home-Fries,
            </Typography>
            <Typography>
                House-made Baked Beans, or Fruit Cup (1.00)
            </Typography>
            <br/>
            <Grid container>
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={4}>
                    <Typography>
                        <b>Egg & Cheese</b>
                    </Typography>
                    <Typography>
                        1 Egg Topped with American Cheese on a<br />
                        Grilled English Muffin (w/side) 7.00<br />
                        with Bacon add 4.00, Ham, or Sausage add 2.00
                    </Typography>
                    <br />
                    <Typography>
                        <b>Western Sandwich</b>
                    </Typography>
                    <Typography>
                        1 Egg with Ham, Onion Topped with
                        <br />
                        American Cheese on Grilled Sourdough 10.00
                    </Typography>
                    <br />
                    <Typography>
                        <b>Florentine Melt</b>
                    </Typography>
                    <Typography>
                        Saut&#232;ed Onions, Spinach, Bacon, and Fried Egg
                        <br />
                        with Swiss Cheese on Your Choice of Bread 12.50
                    </Typography>
                </Grid>
                <Grid item xs={2} />
                <br/>
                <Grid item xs={12} md={4}>
                    <Typography>
                        <b>Meat Lovers Melt</b>
                    </Typography>
                    <Typography>
                        Grilled Cheese Sandwich Stuffed with Bacon,
                        <br />
                        Sausage, Corned Beef Hash, and a Fried Egg 14.00
                    </Typography>
                    <br/>
                    <Typography>
                        <b>Breakfast Burrito</b>
                    </Typography>
                    <Typography>
                        Bacon, Sausage, Onions, Peppers Scrambled with 3
                        <br />
                        Eggs Topped with American Cheese in a Grilled Flour
                        <br/>
                        Tortilla with Salsa 16.00
                    </Typography>
                    <br/>
                    <Typography>
                        <b>Breakfast B.L.T.</b>
                    </Typography>
                    <Typography>
                        The Classic B.L.T. with a Fried Egg on
                        <br />
                        Your Choice of Bread 12.00
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default BreakfastSandwiches;
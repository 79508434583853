import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import Sandwiches from './lunchMenuItems/sandwiches'
import Burgers from './lunchMenuItems/burgers'
import ClubSandwiches from './lunchMenuItems/clubSandwiches'
import Melts from './lunchMenuItems/melts'
import Beverages from './lunchMenuItems/beverages'
import Sides from './lunchMenuItems/sides'

function Lunch() {

    return (
        <div>
            <br/>
            <Grid container>
                <Grid item xs={1}/>
                <Grid item xs={12} md={4}>
                    <Sandwiches/><br/>
                    <ClubSandwiches/><br/>
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={12} md={4}>
                    <Burgers/>
                </Grid>
            </Grid><br/>
            <Melts/>
            <Grid container>
                <Grid item xs={1}/>
                <Grid item item xs={12} md={4}>
                    <br/>
                    <Sides/>
                </Grid>
                <Grid item xs={2}/>
                <Grid item item xs={12} md={4}>
                    <br/>
                    <Beverages/>
                </Grid>
            </Grid><br/>
            <Typography style={{textAlign:'center'}}>
                Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness
            </Typography>
        </div>
    )
}

export default Lunch;

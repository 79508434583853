import React, { useState } from 'react';
import { Button, Grid, Typography } from "@material-ui/core";
import { contentBackground } from '../styles';
import Header from '../components/NewHeader'
import Breakfast from '../components/breakfast'
import Lunch from '../components/lunch'

function Menu() {
    const [menuType, setMenuType] = useState(0)

    return (
        <div style={{
            backgroundImage: `url(/images/irishBenedict.jpg)`,
            backgroundRepeat: "no-repeat",
            height: "100vh",
            width: "100%",
            textAlign: 'center',
            backgroundSize: 'cover'
        }}>
            <Header />
            <div style={{
                paddingTop: '24px', 
                paddingLeft: 'calc(5% - 12px)', 
                alignItems: 'flex-start',
                justifyContent: 'left',
                width: '60%',
                display: 'flex'
            }}>
                <Button style={{ backgroundColor: '#598fb1', display: 'inline-block' }} variant='contained' color='primary' disabled={menuType === 0} onClick={() => setMenuType(0)}>
                    Breakfast
                </Button>
                <Button style={{ backgroundColor: '#598fb1', display: 'inline-block' }} variant='contained' color='primary' disabled={menuType === 1} onClick={() => setMenuType(1)}>
                    Lunch
                </Button>
            </div>
            <div style={contentBackground}>
                {menuType === 0 && (
                    <Breakfast />
                )}
                {menuType === 1 && (
                    <Lunch />
                )}
            </div>
        </div>
    )
}

export default Menu;

import React from 'react';
import { Typography } from "@material-ui/core";
import { menuHeader } from '../breakfastMenuItems/styles'

const ClubSandwiches = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>CLUB SANDWICHES</b>
            </Typography>
            <Typography>
                Served on 3 Slices of Your Choice of Bread<br/>
                with Lettuce, Tomato, Bacon, and Mayo
            </Typography><br/>
            <Typography>
                <b>Turkey Club</b> 15.00
            </Typography><br/>
            <Typography>
                <b>Hamburger Club</b> 18.00
            </Typography><br/>
            <Typography>
                <b>Cheeseburger Club</b> 19.50
            </Typography><br/>
            <Typography>
                <b>Grilled Chicken</b> 15.50
            </Typography>
        </div>
    )
}

export default ClubSandwiches;
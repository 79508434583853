import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import { Grid, MenuItem, Fade, Button, Menu, Typography, Box, Toolbar, IconButton, Modal } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { headerLinks, selectedHeaderLink, headerBackground } from '../styles'
import '../index.css'


const Header = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const curPage = window.location.pathname;
    //TODO need to make small layout menuIcon click show list of pages
    return (
        <div  style={{...headerBackground}}>
            <Grid container>
                <Grid item xs={2} />
                <Grid item xs={8}>
                    <Typography variant="h3" style={{ textAlign: 'center', color: 'white' }}>
                        Benson's Cafe
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{ display: { xs: 'block', md: 'none' }, m: 1 }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="white"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => setModalOpen(true)}
                        >
                            <MenuIcon style={{ color: 'white' }} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ display: { xs: 'none', md: 'block' }, m: 1 }}>
                <Grid container >
                    <Grid item xs={4} />
                    <Grid item xs={1}>
                        <Button
                            onClick={() => history.push('/')}
                            style={curPage === '/' ? selectedHeaderLink : headerLinks}
                        >
                            Home
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => history.push('/menu')} style={curPage === '/menu' ? selectedHeaderLink : headerLinks}
                        >
                            Menu
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => history.push('/about')} style={curPage === '/about' ? selectedHeaderLink : headerLinks}
                        >
                            About
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => history.push('/contact')} style={curPage === '/contact' ? selectedHeaderLink : headerLinks}
                        >
                            Contact Us
                        </Button>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
            </Box>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                className='Menu-modal'
            >
                <div style={{ height: 'calc(100% - 48px)', textAlign: 'center', paddingTop: '24px', paddingBottom: '24px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography
                        variant="h5"
                        onClick={() => {history.push('/'); setModalOpen(false) }}
                        style={{cursor: 'pointer'}}
                    >Home</Typography>
                    <Typography
                        variant="h5"
                        onClick={() => {history.push('/menu'); setModalOpen(false)}}
                        style={{cursor: 'pointer'}}
                    >Menu</Typography>
                    <Typography
                        variant="h5"
                        onClick={() => {history.push('/about'); setModalOpen(false)}}
                        style={{cursor: 'pointer'}}
                    >About</Typography>
                    <Typography
                        variant="h5"
                        onClick={() => {history.push('/contact'); setModalOpen(false)}}
                        style={{cursor: 'pointer'}}
                    >Contact Us</Typography>
                </div>
            </Modal>
        </div>
    )
}

export default Header;
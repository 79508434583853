import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import { menuHeader } from './styles'

const Omelets = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography >
                <b style={menuHeader}>THE BENEDICTS</b>
            </Typography>
            <Typography>
                All Benedicts are Served with Your Choice of
                <br/>
                Home-fries, House-made Baked Beans, or Fruit Cup (1.00)
            </Typography>
            <br/>
            <Grid container>
                <Grid item xs={1} />
                <Grid item xs={12} md={4}>
                    <Typography>
                        <b>The Basic</b>
                    </Typography>
                    <Typography>
                        2 Poached Eggs with Ham on a Grilled
                        <br/>
                        English Muffin Topped with Hollandaise 11.50
                    </Typography>
                    <br />
                    <Typography>
                        <b>The Southern</b>
                    </Typography>
                    <Typography>
                        2 Poached Eggs with Sausage Patties
                        <br/>
                        on a Grilled Biscuit Topped
                        <br/>
                        with sausage Gravy 12.50
                    </Typography>
                </Grid>
                <Grid item xs={0} md={2}/>
                <Grid item xs={12} md={4}>
                    <br/>
                    <Typography>
                        <b>The Florentine</b>
                    </Typography>
                    <Typography>
                        2 Poached Eggs with Spinach,
                         <br />
                        Onions, Tomatoes on a Grilled
                        <br/>
                        English Muffin Topped
                        <br/>
                        with Hollandaise 12.50
                    </Typography>
                    <br/>
                    <Typography>
                        <b>The Veggie</b>
                    </Typography>
                    <Typography>
                        2 Poached Eggs with Saut&#232;ed Mixed
                        <br />
                        Vegetables, on a Grilled English Muffin
                        <br/>
                        Topped with Hollandaise 12.50
                    </Typography>
                    <br/>
                    <Typography>
                        <b>The Irish</b>
                    </Typography>
                    <Typography>
                        2 Poached Eggs with House-made
                        <br />
                        Corned Beef Hash, on a Grilled English Muffin
                        <br/>
                        Topped with Hollandaise 14.50
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Omelets;
import React from 'react';
import { Typography } from "@material-ui/core";
import { menuHeader } from '../breakfastMenuItems/styles'

const Burgers = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>BURGERS</b>
            </Typography>
            <Typography>
                On Homemade Grilled Burger Rolls with<br/>
                Choice of French Fries or House-made Chips<br/>
                Substitute Onion Rings 2.00
            </Typography><br/>
            <Typography>
                <b>Hamburger</b><br/>
                8oz. Burger,<br/>
                Lettuce, Tomato 15.00
            </Typography><br/>
            <Typography>
                <b>Cheeseburger</b><br/>
                8oz. Burger, American Cheese,<br/>
                Lettuce, Tomato 16.00
            </Typography><br/>
            <Typography>
                <b>Mushroom Swiss</b><br/>
                8oz. Burger, Mushrooms, Bacon,<br/>
                and Swiss Cheese 19.50
            </Typography><br/>
            <Typography>
                <b>Western Burger</b><br/>
                8oz. Burger, Mushroom, Onion, Pepper,<br/>
                Bacon and Swiss 20.00
            </Typography>
        </div>
    )
}

export default Burgers;
import React from 'react';
import { Typography } from "@material-ui/core";
import { menuHeader } from './styles'

const createLabel = (label, price) => {
    return {
        label,
        price
    }
}

const sidesList = [
    createLabel('1 Egg', '1.50'),
    createLabel('Toast', '2.50'),
    createLabel('Biscuit', '3.00'),
    createLabel('Home-fries', '3.50'),
    createLabel('House-made Corned Beef Hash', '7.00'),
    createLabel('Grilled English Muffin', '3.50'),
    createLabel('Grilled Muffin', '4.00'),
    createLabel('Fruit Cup', '3.50'),
    createLabel('Bacon or Sausage Patties', '5.00'),
    createLabel('Ham or Sausage Links', '4.00'),
    createLabel('Extra Cheese', '.75'),
    createLabel('Side Hollandaise', '2.00'),
    createLabel('Crock Beans', '4.00'),
    createLabel('Sausage Gravy', '2.00')
    

]

const buildSideDisplayElement = (sideObject) => {
    return (
        <div style={{ display: 'inline-flex', width: '75%' }}>
            <Typography style={{ textAlign: 'left', width: '70%' }}>
                {sideObject.label}
            </Typography>
            <Typography style={{ textAlign: 'right', width: '30%' }}>
                {sideObject.price}
            </Typography>
        </div>
    )
}

const Sides = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography >
                <b style={menuHeader}>SIDES</b>
            </Typography>
            {sidesList.map(side => buildSideDisplayElement(side))}
        </div>
    )
}

export default Sides;
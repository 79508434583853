import React from 'react';
import { Typography } from "@material-ui/core";
import { menuHeader } from './styles'

const BasicEggBreakfast = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>BASIC EGG BREAKFAST</b>
            </Typography>
            <br/>
            <Typography>
                <b>#1 - 1 Egg,</b> Toast, and Home-fries 6.50
            </Typography>
            <Typography>
                <b>#2 - 2 Eggs,</b> Toast, and Home-fries 7.50
            </Typography>
            <Typography>
                <b>#3 - 3 Eggs,</b> Toast, and Home-fries 8.50
            </Typography>
            <br/>
            <Typography>
                With Bacon or Sausage Patties add 5.00
            </Typography>
            <Typography>
                Ham or Sausage Links add 4.00
            </Typography>
            <Typography>
                With House-made Corned Beef Hash add 7.00
            </Typography>
        </div>
    )
}

export default BasicEggBreakfast;
import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import { menuHeader } from '../breakfastMenuItems/styles'

const Melts = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>MELTS AND WRAPS</b>
            </Typography>
            <Typography>
                Served with French Fries or<br/>
                House-made Chips
            </Typography><br/>
            <Grid container>
                <Grid item xs={1}/>
                <Grid item xs={12} md={4}>
                    <Typography>
                        <b>Plain Grilled Cheese</b><br/>
                        Choice of Bread with 2 Slices of Cheese 7.50
                    </Typography><br/>
                    <Typography>
                        <b>Grilled Cheese</b><br/>
                        2 Slices of Cheese, Tomato, Bacon<br/>
                        on Your Choice of Bread 10.00
                    </Typography><br/>
                    <Typography>
                        <b>Honey Buffalo Chicken Wrap</b><br/>
                        Chicken Fingers Tossed in<br/>
                        Honey Buffalo Sauce with<br/>
                        Lettuce and Tomato 13.00
                    </Typography><br/>
                    <Typography>
                        <b>Chicken Caesar Salad</b><br/>
                        Grilled Chicken Breast, Caesar Dressing,<br/>
                        Romaine Lettuce, Croutons,<br/>
                        and Parmesan Cheese, no side 13.00<br/>
                        as a Wrap with Side 14.00
                    </Typography><br/>
                    <Typography>
                        <b>Corned Beef Reuben</b><br/>
                        House-made Corned Beef, Sauerkraut,<br/>
                        Swiss Cheese, 1000 Island Dressing on<br/>
                        Rye Bread, Grilled 13.00
                    </Typography>
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={12} md={4}>
                    <br/>
                    <Typography>
                        <b>Turkey Reuben</b><br/>
                        Oven Roasted Turkey Breast, Sauerkraut,<br/>
                        Swiss Cheese, 1000 Island Dressing, on<br/>
                        Rye Bread, Grilled 12.50
                    </Typography><br/>
                    <Typography>
                        <b>Steak Tip and Cheese</b><br/>
                        House Marinated Steak Tips with Mushrooms,<br/>
                        Onions, Peppers, Wrapped in a Flour Tortilla,<br/>
                        Grilled 22.00
                    </Typography><br/>
                    <Typography>
                        <b>Chicken Quesadilla</b><br/>
                        Seasoned Grilled Chicken,<br/>
                        Saut&#232;ed Onions and Peppers,<br/>
                        Cheddar Cheese on<br/>
                        a Flour Tortilla, Served with Salsa 15.00
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Melts;
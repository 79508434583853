import React, { useState } from 'react'
import Header from '../components/NewHeader'
import { contentBackground } from '../styles'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import axios from 'axios'

const Contact = () => {
    const [state, setState] = useState({
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        subject: undefined,
        message: undefined,
        submitted: false
    })
    const [responseStatus, setResponseStatus] = useState(undefined)

    const onSubmit = () => {
        setState({ ...state, submitted: true });
        axios.post('/email/send', state)
            .then((res) => setResponseStatus(res.status))
            .catch((err) => {
                setState({ ...state, submitted: false })
                setResponseStatus(500)
            })
    }

    return (
        <div style={{
            backgroundImage: `url(/images/orderWindow.jpg)`,
            backgroundRepeat: "no-repeat",
            height: "100vh",
            width: "100%",
            textAlign: 'center',
            backgroundSize: 'cover'
        }}>
            <Header />
            <div style={{ ...contentBackground, marginTop: '54px' }}>
                {responseStatus === 200 && (
                    <Typography> Thank you for contacting us! </Typography>
                )}
                {(responseStatus === undefined || responseStatus === 500) && (
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item container justify="center" alignItems="center" direction={'row'} spacing={1}>
                            <Grid item lg={3} md={3} sm={7} xs={7}>
                                <TextField
                                    label="First Name"
                                    onChange={(event) => setState({ ...state, firstName: event.target.value })}
                                    variant="outlined"
                                    required
                                    error={state.submitted && !state.firstName}
                                    style={{ alignSelf: 'left', color: 'white', width: '100%' }}
                                />
                            </Grid>
                            <Grid item lg={3} md={3} sm={7} xs={7}>
                                <TextField
                                    label="Last Name"
                                    onChange={(event) => setState({ ...state, lastName: event.target.value })}
                                    variant="outlined"
                                    required
                                    error={state.submitted && !state.lastName}
                                    style={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item justify="center" alignItems="center" direction={'row'} spacing={1}>
                            <Grid item lg={6} md={6} sm={7} xs={7}>
                                <TextField
                                    label="Email"
                                    onChange={(event) => setState({ ...state, email: event.target.value })}
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    required
                                    error={state.submitted && !state.email}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item justify="center" alignItems="center" direction={'row'} spacing={1}>
                            <Grid item lg={6} md={6} sm={7} xs={7}>
                                <TextField
                                    label="Subject"
                                    onChange={(event) => setState({ ...state, subject: event.target.value })}
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    required
                                    error={state.submitted && !state.subject}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item justify="center" alignItems="center">
                            <Grid item lg={6} md={6} sm={7} xs={7}>
                                <TextField
                                    label="Message"
                                    onChange={(event) => setState({ ...state, message: event.target.value })}
                                    multiline
                                    rows={4}
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    required
                                    error={state.submitted && !state.message}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item xs={12}
                        >
                            <Button
                                style={{ backgroundColor: '#598fb1' }}
                                disabled={state.submitted}
                                variant="contained"
                                color="primary"
                                onClick={() => onSubmit()}
                            >Submit</Button>
                        </Grid>
                    </Grid>)}
            </div>
        </div>
    )
}

export default Contact;

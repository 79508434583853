import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import { menuHeader } from './styles'

const Omelets = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Typography >
                <b style={menuHeader}>OMELETS</b>
            </Typography>
            <Typography>
                All Omelets are Served with Toast and Your Choice of Home-Fries,
                <br/>
                House-made Baked Beans, or Fruit Cup (1.00)
            </Typography>
            <br/>
            <Grid container>
                <Grid item xs={1} />
                <Grid item xs={12} md={4}>
                    <Typography>
                        <b>Egg and Cheese</b>
                    </Typography>
                    <Typography>
                        American Cheese in a 3 Egg Omelet 11.00
                    </Typography>
                    <br />
                    <Typography>
                        <b>Western</b>
                    </Typography>
                    <Typography>
                        Ham, Onion, and Peppers, American Cheese<br/>
                        in a 3 egg omelet 13.00
                    </Typography>
                    <br />
                    <Typography>
                        <b>Bacon and Cheese</b>
                    </Typography>
                    <Typography>
                        3 Eggs, American Cheese With Bacon 13.00                   
                    </Typography>
                    <br/>
                    <Typography>
                        <b>Bacon, Mushroom, and Swiss</b>
                    </Typography>
                    <Typography>
                        3 Eggs, Bacon, Mushrooms, Swiss Cheese 14.00
                    </Typography>
                    <br/>
                    <Typography>
                        <b>Broccoli, Ham, and Cheese</b>
                    </Typography>
                    <Typography>
                        3 Eggs Broccoli, Ham, and American Cheese 14.00
                    </Typography>
                </Grid>
                <Grid item xs={0} md={2} />
                <Grid item xs={12} md={4}>
                    <br/>
                    <Typography>
                        <b>Hash and Cheese</b>
                    </Typography>
                    <Typography>
                        3 Eggs, House-made Corned Beef Hash
                         <br />
                        and Cheese 16.00
                    </Typography>
                    <br/>
                    <Typography>
                        <b>Vegetable Omelet</b>
                    </Typography>
                    <Typography>
                        3 Eggs, Onions, Peppers, Mushrooms, Spinach,
                        <br />
                        Tomato, and Broccoli, with Cheese 14.00
                    </Typography>
                    <br/>
                    <Typography>
                        <b>Meat Lovers</b>
                    </Typography>
                    <Typography>
                        3 Eggs, Ham, Bacon, Sausage, House-Made
                        <br />
                        Corned Beef Hash and American Cheese 18.00
                    </Typography>
                    <br/>
                    <Typography>
                        <b>Steak Tip</b>
                    </Typography>
                    <Typography>
                        3 Eggs, House Marinated Steak Tips,
                        <br />
                        Mushrooms, Onions, Peppers, and
                        <br/>
                        American Cheese 27.00
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Omelets;
import React from 'react';
import { Typography } from "@material-ui/core";
import { menuHeader } from '../breakfastMenuItems/styles'

const Sandwiches = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <Typography >
                <b style={ menuHeader }>SANDWICHES</b>
            </Typography>
            <Typography>
                Sanwiches are Served with French Fries or<br/>
                House-made Chips<br/>
                Substitute Onion Rings 2.00
            </Typography><br/>
            <Typography>
                <b>Chicken Salad</b><br/>
                Homemade Chicken Salad, Lettuce,<br/>
                Tomato, Choice of Bread 12.00
            </Typography><br/>
            <Typography>
                <b>The Classic B.L.T.</b><br/>
                Bacon, Lettuce, Tomato, Mayo,<br/>
                Choice of Bread 13.00
            </Typography><br/>
            <Typography>
                <b>Turkey</b><br/>
                Oven Roasted Turkey Breast, Lettuce,<br/>
                Tomato, Cheese, and Mayo on<br/>
                a Homemade Grilled Burger Roll 12.00
            </Typography><br/>
            <Typography>
                <b>Crispy Chicken</b><br/>
                Crispy Fried Chicken Fingers,<br/>
                Lettuce, Tomato, and Mayo on<br/>
                a Homemade Grilled Burger Roll 13.00
            </Typography>
        </div>
    )
}

export default Sandwiches;
import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import BasicEggBreakfast from './breakfastMenuItems/basicEggBreakfast'
import BreakfastSandwiches from './breakfastMenuItems/breakfastSandwiches'
import Omelets from './breakfastMenuItems/omelets'
import Benedicts from './breakfastMenuItems/benedicts'
import BreakfastSpecials from './breakfastMenuItems/breakfastSpecials'
import Griddle from './breakfastMenuItems/griddle'
import Sides from './breakfastMenuItems/sides'
import Oatmael from './breakfastMenuItems/oatmeal'

function Breakfast() {

    return (
        <div>
            <br/>
            <BasicEggBreakfast style={{textAlign: 'center'}}/>
            <br/>
            <BreakfastSandwiches/>
            <br/>
            <Omelets/>
            <br/>
            <Benedicts/>
            <br/>
            <Grid container>
                <Grid item xs={1}/>
                <Grid item xs={12} md={4}>
                    <BreakfastSpecials/><br/>
                </Grid>
                <Grid item xs={0} md={2}/>
                <Grid item xs={12} md={4}>
                    <Griddle/><br/>
                    <Sides/> <br/>
                    <Oatmael/>
                </Grid>
            </Grid><br/>
            <Typography style={{textAlign:'center'}}>
                Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness
            </Typography>
            <Typography style={{textAlign:'center'}}>
                Please tell your server of any known food allergies
            </Typography>
        </div>
    )
}

export default Breakfast;
